import React, { useState, useEffect, CSSProperties } from 'react';

import styled from '@emotion/styled';

import * as Fuel from '@convoy/fuel';

import { useUpdateTrailerServiceRequests } from '~/hooks';
import { ServiceRequestStatus } from '~/services/apollo';
import {
  allOptions as serviceRequestStatusOptions,
  doesStatusNeedConfirmation,
  getOptionsForStatus,
  ServiceRequestStatusOption,
} from '~/utils/serviceRequestStatus/ServiceRequestStatusOptions';

import Modal from './Modal';

const strings = {
  confirmationModal: {
    headerText: `Do you want to update FHWA Inspection for '$trailerNumber' to '$newStatus'?`,
    bodyText: `This will automatically reschedule a new FHWA inspection for
    this trailer to next year.\n
    If confirm, you cannot undo this.\n`,
    cancelButton: `Cancel`,
    confirmButton: `Confirm`,
  },
};

type SelectServiceRequestStatusOption = {
  value: string;
  label: string;
  color?: keyof typeof Fuel.Color;
};

const StatusSelect = styled(Fuel.Select)({
  width: '100%',
});

const Spinner = styled(Fuel.Spinner)({
  marginLeft: Fuel.Unit.XS,
});

interface Props {
  className?: string;
  trailerNumber: string;
  serviceRequestId: string;
  status: ServiceRequestStatus;
  shopId?: string;
  showColor?: boolean;
}

const ServiceRequestStatusSelect = (props: Props) => {
  const {
    className,
    trailerNumber,
    serviceRequestId,
    status,
    shopId,
    showColor,
  } = props;
  const [options, setOptions] = useState(getSelectOptionsForStatus(status));
  const [selectedStatus, setSelectedStatus] =
    useState<SelectServiceRequestStatusOption>();
  const [initialStatus, setInitialStatus] =
    useState<SelectServiceRequestStatusOption>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateServiceRequests, { loading }] =
    useUpdateTrailerServiceRequests();

  useEffect(() => {
    const options = getSelectOptionsForStatus(status);
    setOptions(options);
    const statusOption = Object.values(allOptions).find(
      option => option.value === status,
    );
    setSelectedStatus(statusOption);
    setInitialStatus(statusOption);
  }, [status]);

  const colorStyles = getColorStyles({
    showColor,
    color: selectedStatus?.color,
  });

  const onChangeStatus = async (
    statusOption: SelectServiceRequestStatusOption,
  ) => {
    if (statusOption.value === selectedStatus?.value) {
      return;
    }

    setSelectedStatus(statusOption);
    const status = statusOption.value as ServiceRequestStatus;
    if (doesStatusNeedConfirmation(status)) {
      setShowConfirmationModal(true);
    } else {
      await doStatusUpdate(status);
    }
  };

  const doStatusUpdate = async (status: ServiceRequestStatus) => {
    updateServiceRequests({
      ids: [serviceRequestId],
      replaceWith: {
        status,
        shopId: status === ServiceRequestStatus.NotRequested ? null : shopId,
      },
    });
  };

  const handleModalOnClose = () => {
    setSelectedStatus(initialStatus);
    setShowConfirmationModal(false);
  };

  const handleOnConfirm = async () => {
    await doStatusUpdate(selectedStatus!.value as ServiceRequestStatus);
    setShowConfirmationModal(false);
  };

  // This value is used to help determine when a menu flips between expanding
  // downwards or upwards
  const minMenuHeight = 350;

  return (
    <>
      <StatusSelect
        className={className}
        options={options}
        onChange={onChangeStatus}
        value={selectedStatus}
        minMenuHeight={minMenuHeight}
        menuPlacement='auto'
        disabled={loading}
        styles={{
          control: (provided: CSSProperties) => ({
            ...provided,
            height: Fuel.Unit.LG,
            minHeight: `${Fuel.Unit.LG} !important`,
            border: 'none !important',
            borderRadius: '2 !important',
            ...colorStyles,
          }),
          indicatorsContainer: (provided: CSSProperties) => ({
            ...provided,
            display: 'flex !important',
            marginTop: '0px !important',
            alignItems: 'center !important',
          }),
          valueContainer: (provided: CSSProperties) => ({
            ...provided,
            ...Fuel.Text.Size.SM,
          }),
        }}
      />
      {loading && <Spinner size='MD' />}
      <Modal
        isOpen={showConfirmationModal}
        onClose={handleModalOnClose}
        title={strings.confirmationModal.headerText
          .replace('$trailerNumber', trailerNumber)
          .replace('$newStatus', selectedStatus?.label ?? '--')}
        actionButtonFunction={handleOnConfirm}
        actionButtonText={strings.confirmationModal.confirmButton}
        size={'MD'}
      >
        {strings.confirmationModal.bodyText}
      </Modal>
    </>
  );
};

const getColorStyles = ({
  showColor,
  color,
}: {
  showColor?: boolean;
  color?: keyof typeof Fuel.Color;
}): CSSProperties => {
  const defaultColorStyles = {
    backgroundColor: `${Fuel.Color.Light.N200} !important`,
    color: `${Fuel.Color.Midnight} !important`,
  };

  if (!showColor || !color) {
    return defaultColorStyles;
  }

  switch (color) {
    case 'Yellow':
      return {
        backgroundColor: `${Fuel.Color.Yellow.N50} !important`,
        color: `${Fuel.Color.Yellow.N800} !important`,
      };
    case 'Red':
      return {
        backgroundColor: `${Fuel.Color.Roarange.N50} !important`,
        color: `${Fuel.Color.Red.N800} !important`,
      };
    case 'Dark':
    default:
      return defaultColorStyles;
  }
};

const serviceRequestStatusToColorMap: Map<
  ServiceRequestStatus,
  keyof typeof Fuel.Color
> = new Map([
  [ServiceRequestStatus.NotRequested, 'Dark'],
  [ServiceRequestStatus.Requested, 'Dark'],
  [ServiceRequestStatus.Deferred, 'Yellow'],
  [ServiceRequestStatus.InProgress, 'Dark'],
  [ServiceRequestStatus.NotAllowedOnSite, 'Red'],
  [ServiceRequestStatus.TrailerNotAccessible, 'Red'],
  [ServiceRequestStatus.TrailerNotFound, 'Red'],
  [ServiceRequestStatus.UnableToComplete, 'Red'],
  [ServiceRequestStatus.CompletedWithFollowUps, 'Yellow'],
  [ServiceRequestStatus.Completed, 'Yellow'],
]);

const convertToSelectOptions = (
  option: ServiceRequestStatusOption,
): SelectServiceRequestStatusOption => ({
  value: option.value.valueOf(),
  label: option.label,
  color: serviceRequestStatusToColorMap.get(option.value),
});

const allOptions: Record<
  ServiceRequestStatus,
  SelectServiceRequestStatusOption
> = Object.values(serviceRequestStatusOptions).reduce((records, option) => {
  const status = option.value;
  records[status] = convertToSelectOptions(option);
  return records;
}, {} as Record<ServiceRequestStatus, SelectServiceRequestStatusOption>);

const getSelectOptionsForStatus = (
  status: ServiceRequestStatus,
): SelectServiceRequestStatusOption[] => {
  const options = getOptionsForStatus(status);
  return options.map(option => convertToSelectOptions(option));
};

export default ServiceRequestStatusSelect;
