import React from 'react';

import styled from '@emotion/styled';
import gql from 'graphql-tag';

import * as Fuel from '@convoy/fuel';

import {
  LocationEventType,
  TrailerAndFacilityInfoFragment,
} from '~/services/apollo';
import { DEFAULT_EMPTY_VALUE } from '~/utils/strings';

const Card = styled.div({
  display: 'flex',
  gap: Fuel.Unit.SM,
  '>div:nth-of-type(2)': {
    flex: 1,
  },
});

const DetailsBlock = styled.div({
  display: 'grid',
  gridTemplateColumns: 'min-content auto',
  gridAutoRows: 'min-content',
  gridColumnGap: Fuel.Unit.SM,
  gridRowGap: Fuel.Unit.XXS,
  padding: `${Fuel.Unit.SM} ${Fuel.Unit.LG}`,
  backgroundColor: Fuel.Color.Light.N100,
});

const DetailsTitle = styled.div({
  ...Fuel.Text.Weight.Bold,
  ...Fuel.Text.Size.XS,
  lineHeight: Fuel.Text.Size.SM.lineHeight,
  color: Fuel.Color.Dark.N800,
});

const DetailsData = styled.div({
  ...Fuel.Text.Weight.Normal,
  ...Fuel.Text.Size.SM,
  color: Fuel.Color.Midnight,
  whiteSpace: 'pre-wrap',
});

const strings = {
  trailerInfoLabels: {
    vin: `VIN`,
    make: `MAKE`,
    model: `MODEL`,
    year: `YEAR`,
    provider: `PROVIDER`,
  },
  facilityInfoLabels: {
    facility: 'FACILITY',
    address: 'ADDRESS',
    restrictions: 'RESTRICTIONS',
  },
};

interface Props extends TrailerAndFacilityInfoFragment {
  className?: string;
}

const TrailerDetailsCard = (props: Props) => {
  const { className, vin, make, model, year, provider, lastLocationEvent } =
    props;

  const atLocation = lastLocationEvent?.type === LocationEventType.Enter;
  const facilityName = atLocation
    ? lastLocationEvent?.parcel.trailerYard?.name
    : DEFAULT_EMPTY_VALUE;
  const facilityAddress = atLocation
    ? lastLocationEvent?.parcel.preferredLocationProfile?.address?.full
    : DEFAULT_EMPTY_VALUE;
  const yardRestrictions = atLocation
    ? lastLocationEvent?.parcel.trailerYard?.yardRestrictions?.opsNote
    : DEFAULT_EMPTY_VALUE;

  return (
    <Card className={className}>
      <DetailsBlock>
        <DetailsTitle>{strings.trailerInfoLabels.vin}</DetailsTitle>
        <DetailsData>{vin || DEFAULT_EMPTY_VALUE}</DetailsData>

        <DetailsTitle>{strings.trailerInfoLabels.make}</DetailsTitle>
        <DetailsData>{make || DEFAULT_EMPTY_VALUE}</DetailsData>

        <DetailsTitle>{strings.trailerInfoLabels.model}</DetailsTitle>
        <DetailsData>{model || DEFAULT_EMPTY_VALUE}</DetailsData>

        <DetailsTitle>{strings.trailerInfoLabels.year}</DetailsTitle>
        <DetailsData>{year || DEFAULT_EMPTY_VALUE}</DetailsData>

        <DetailsTitle>{strings.trailerInfoLabels.provider}</DetailsTitle>
        <DetailsData>{provider || DEFAULT_EMPTY_VALUE}</DetailsData>
      </DetailsBlock>

      <DetailsBlock>
        <DetailsTitle>{strings.facilityInfoLabels.facility}</DetailsTitle>
        <DetailsData>{facilityName || DEFAULT_EMPTY_VALUE}</DetailsData>

        <DetailsTitle>{strings.facilityInfoLabels.address}</DetailsTitle>
        <DetailsData>{facilityAddress || DEFAULT_EMPTY_VALUE}</DetailsData>

        <DetailsTitle>{strings.facilityInfoLabels.restrictions}</DetailsTitle>
        <DetailsData>{yardRestrictions || DEFAULT_EMPTY_VALUE}</DetailsData>
      </DetailsBlock>
    </Card>
  );
};

TrailerDetailsCard.TrailerAndFacilityInfo = gql`
  fragment TrailerAndFacilityInfo on Trailer {
    id
    key
    vin
    make
    model
    year
    provider
    lastLocationEvent {
      type
      parcel {
        preferredLocationProfile {
          address {
            full
          }
        }
        trailerYard {
          name
          yardRestrictions {
            opsNote
          }
        }
      }
    }
  }
`;

export default TrailerDetailsCard;
