import { ServiceRequestStatus } from '~/services/apollo';

import { intersect } from '../collections';

export type ServiceRequestStatusOption = {
  value: ServiceRequestStatus;
  label: string;
};

// using Record instead of Map to retain order
export const allOptions: Record<
  ServiceRequestStatus,
  ServiceRequestStatusOption
> = {
  'NOT_REQUESTED': {
    value: ServiceRequestStatus.NotRequested,
    label: 'Not Requested',
  },
  'REQUESTED': {
    value: ServiceRequestStatus.Requested,
    label: 'Requested',
  },
  'DEFERRED': {
    value: ServiceRequestStatus.Deferred,
    label: 'Deferred',
  },
  'IN_PROGRESS': {
    value: ServiceRequestStatus.InProgress,
    label: 'In Progress',
  },
  'NOT_ALLOWED_ON_SITE': {
    value: ServiceRequestStatus.NotAllowedOnSite,
    label: 'Not Allowed On-Site',
  },
  'TRAILER_NOT_ACCESSIBLE': {
    value: ServiceRequestStatus.TrailerNotAccessible,
    label: 'Trailer Not Accessible',
  },
  'TRAILER_NOT_FOUND': {
    value: ServiceRequestStatus.TrailerNotFound,
    label: 'Trailer Not Found',
  },
  'UNABLE_TO_COMPLETE': {
    value: ServiceRequestStatus.UnableToComplete,
    label: 'Unable to Complete',
  },
  'COMPLETED_WITH_FOLLOW_UPS': {
    value: ServiceRequestStatus.CompletedWithFollowUps,
    label: 'Completed + Follow-ups',
  },
  'COMPLETED': {
    value: ServiceRequestStatus.Completed,
    label: 'Completed',
  },
};

const statusToOptionsOverrideMap: Map<
  ServiceRequestStatus,
  ServiceRequestStatusOption[]
> = new Map([
  [ServiceRequestStatus.CompletedWithFollowUps, [allOptions['COMPLETED']]],
  [ServiceRequestStatus.Completed, [allOptions['COMPLETED']]],
]);
export function getOptionsForStatus(
  status: ServiceRequestStatus,
): ServiceRequestStatusOption[] {
  // returns all options if status isn't in override map
  return statusToOptionsOverrideMap.get(status) ?? Object.values(allOptions);
}

export function getOptionsForStatuses(
  statuses: Set<ServiceRequestStatus>,
): ServiceRequestStatusOption[] {
  // empty statuses => empty options
  if (!statuses || statuses.size < 1) return [];

  // 2. get the options for all statuses by taking an intersection of the
  // options for each status
  const options: ServiceRequestStatusOption[] = [...statuses].reduce(
    (currentOptions, status) => {
      if (currentOptions.length === 0) return [];
      const optionsForThisStatus = getOptionsForStatus(status);
      const intersection = intersect(currentOptions, optionsForThisStatus);
      return intersection;
    },
    Object.values(allOptions), // init currentOptions to be allOptions
  );
  return options;
}

const completedStatusSet = new Set<ServiceRequestStatus>([
  ServiceRequestStatus.Completed,
  ServiceRequestStatus.CompletedWithFollowUps,
]);
export function doesStatusNeedConfirmation(status: ServiceRequestStatus) {
  return completedStatusSet.has(status);
}
