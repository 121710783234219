import React from 'react';

import styled from '@emotion/styled';
import FileCopySharp from '@material-ui/icons/FileCopySharp';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';

import * as Fuel from '@convoy/fuel';

const CopyIcon = styled(Fuel.Icon)({
  ':hover': {
    cursor: 'pointer',
  },
  ':active': {
    color: Fuel.Color.Midnight,
  },
});

interface Props {
  copyValue: string;
  className?: string;
}

const CopyToClipboard = ({ copyValue, className }: Props) => (
  <ReactCopyToClipboard text={copyValue}>
    <CopyIcon
      icon={FileCopySharp}
      size='SM'
      color={Fuel.Color.Dark.N600}
      className={className}
    />
  </ReactCopyToClipboard>
);

export default CopyToClipboard;
