import React from 'react';

import styled from '@emotion/styled';

import * as Fuel from '@convoy/fuel';
import { CheckboxProps } from '@convoy/fuel.form/Checkbox/Checkbox';

const StyledCheckbox = styled(Fuel.Checkbox)({
  ':hover': {
    cursor: 'pointer',
  },
});

const Checkbox = (props: CheckboxProps) => {
  const { ref, ...restOfProps } = props;
  return <StyledCheckbox opaque={false} {...restOfProps} />;
};

export default Checkbox;
